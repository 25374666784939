import { getRedirectResult, signInWithRedirect, GoogleAuthProvider, signInWithPopup, OAuthProvider } from "firebase/auth";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../../firebase";
import { AuthContext } from "../../context/AuthContext";
import { FcGoogle } from 'react-icons/fc'
import { HiOutlineLogin } from 'react-icons/hi';
import { AiOutlineYahoo } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import './oauthlogin.css';



export default () => {
    const { dispatch } = useContext(AuthContext)
    const navigate = useNavigate()
    const gProvider = new GoogleAuthProvider();
    const yProvider = new OAuthProvider('yahoo.com');
    // const fbProvider = new FacebookAuthProvider();
    const [oauthUser, setoAuthUser] = useState({})

    const checkForExistingUser = async (user) => {

        const userRef = doc(db, 'users', user.uid)
        const docSnap = await getDoc(userRef)
        if (docSnap.exists()) {
            // console.log('already exists!!!!!!');

            navigate('/dashboard')
        } else {
            // console.log('doesnt exist yet');
            await setDoc(doc(db, 'users', user.uid), {
                email: user.email,
                emailVerified: true,
                img: user.photoURL || '',
                timeStamp: serverTimestamp(),
                username: user.displayName || '',
            })
            navigate('/dashboard')
        }
    }

    const redirResults = async () => {

        await getRedirectResult(auth)
            .then((result) => {
                // const credential = GoogleAuthProvider.credentialFromResult(result);
                // const token = credential.accessToken;
                const user = result.user;
                console.log(user);

                // setoAuthToken(token);

                if (result) {
                    Swal.fire({
                        title: 'Loading...',
                        timer: 1000,
                        didOpen: () => {
                            Swal.showLoading()
                        }
                    })
                }
                setoAuthUser(user);


                dispatch({ type: 'LOGIN', payload: user})

                checkForExistingUser(user);

            })
            .catch((error) => {
                 console.log(error.code)
                 console.log(error.message)
                 console.log(error.customData?.email)
            })
    }

    useEffect(() => {
        redirResults();
    }, [])

    const handleLogin = async (e) => {
        e.preventDefault()
        let authP = e.target.name

        try {
            switch(authP) {
                case "google":
                    await signInWithRedirect(auth, gProvider)
                    break;
                case "yahoo":
                    await signInWithRedirect(auth, yProvider)
                    break;
                default:
                    console.log('Error, try again.')
            }
            // await signInWithRedirect(auth, gProvider)

        } catch (error) {
            if (error) {
                console.log(error)
            }
        }
    }

    return (
        <>
        <div className="flex items-center justify-center flex-col">
            <h1 className="italic text-xl text-center mb-4">Passwordless providers:</h1>

            <button className="btn btn-lg btn-neutral-content hover:bg-base-300 hover:text-primary hover:border-secondary my-2 group" name="google" onClick={e => handleLogin(e)}><FcGoogle className="w-12 h-12 group-hover:scale-125 ease-in-out duration-300" />&nbsp;&nbsp;Google OAuth2</button>
            <button className="btn btn-lg btn-neutral-content hover:bg-base-300 hover:text-primary hover:border-secondary my-2 group" name="yahoo" onClick={e => handleLogin(e)}><AiOutlineYahoo className="w-12 h-12 group-hover:scale-125 ease-in-out duration-300" color="purple" />&nbsp;&nbsp;yahoo! OAuth2</button>
            {/* <h1>Sign in with Facebook</h1>
            <button className="oauthButton" name="facebook" onClick={e => handleLogin(e)}><FcGoogle />&nbsp;&nbsp;Facebook OAuth2</button> */}
            <div id="noteTainer" className="flex items-center flex-col px-4">
                <span className="text-center font-bold text-xl">Note:</span><br />
                <span>This will <span className="underline italic">redirect you to choose your email</span> and then back to your dashboard.</span>
            </div>
            <div id="redir" className="mt-16 flex items-center justify-center flex-row border p-4 border-primary">
                <h4 className="mr-2">Return to Login Page</h4>
                <Link to={'/'}><HiOutlineLogin className="w-16 h-16 text-info hover:text-secondary hover:scale-125 ease-in-out duration-200" /></Link>
            </div>
        </div>
        </>
    )
}