import { updateDoc, doc,} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { BsInfoCircle } from 'react-icons/bs';
import { BsEyeFill } from "react-icons/bs";
import { HiOutlineExternalLink } from 'react-icons/hi';
import Swal from 'sweetalert2';
import { db } from '../../firebase';
import { GrEdit } from 'react-icons/gr';
import { FaRegEdit } from "react-icons/fa";
import { MdCallSplit } from 'react-icons/md';
import './gifts.css';
import blankProfilePic from '../../images/blank-profile.webp';
import GiftStatusToggle from "./toggle_claimed_status/GiftStatusToggle";
import { formatGiftLink } from './gifts_utility_funs/formatGiftLink';
import UpdateGiftInfo from '../dialog_swal_replacement/UpdateGiftInfo';
import ViewGiftInfoDialog from '../dialog_swal_replacement/ViewGiftInfoDialog';
import EmptyGiftAndSearch from './gift_components/EmptyGiftAndSearch';



export default ({ giftArray, originalGiftArray, user, inFocus }) => {
    // {giftArray && giftArray.map(i => (<ul key={i.giftName}><li><b>Gift: </b>{i.giftName}</li><li><b>Link: </b>{i.giftLink}</li><li><b>Requestor: </b>{i.requestor}</li><b>Claimed: </b><li>{i.claimed ? "claimed" : i.requestor === user.email ? 'Unavailable to requestor!' : "not claimed"}</li></ul>))}
    const [checked, setChecked] = useState(false);
    const [notChecked, setNotChecked] = useState(true);
    const [isClaimed, setIsClaimed] = useState(Boolean);
    const [toggle, setToggle] = useState(false);
    const [splittable, setSplittable] = useState(false);
    const [wasEdited, setWasEdited] = useState(false);
    const [claimeeArray, setClaimeeArray] = useState([]);
    const [screenWidth, setScreenWidth] = useState(Boolean);


    const location = useLocation()
    const eventId = location.pathname?.split("/")[3]
    const eventRef = doc(db, 'events', eventId)

    let inc = 0;

    useEffect(() => {
        setClaimeeArray(giftArray);
        setScreenWidth(window.visualViewport.width);

        // return () => {
        //     unsub2()
        // }

    }, [isClaimed, wasEdited, screenWidth])

    const handleEdit = async (e, index) => {
        // console.log(e.target);
        e.preventDefault()

        let name = giftArray[index].giftName
        let link = giftArray[index].giftLink
        let cost = giftArray[index]?.giftCost

        const { value : editedText } = await Swal.fire({
            title: 'Edit values...',
            confirmButtonColor: '',
            html: ` <div style="display: flex; align-items:center; justify-content:center; flex-direction: column;">
                    <label>Item Name</label><input id="textEditIn1" placeholder="${name}" />
                    <label>Item Link</label><input id="textEditIn2" placeholder="${link !== '' ? link : ''}" />
                    <label>Item Cost</label><input id="textEditIn3" placeholder="${cost ? cost : '$'}" />
                    </div>`,
            focusConfirm: false,
            preConfirm: () => {
                return [
                    document.getElementById('textEditIn1').value,
                    document.getElementById('textEditIn2').value,
                    document.getElementById('textEditIn3').value
                ]
            }

        })
        if (editedText) {
            let updatedGiftArr = [...giftArray, ...originalGiftArray]
            let updatedArr = [...new Set(updatedGiftArr)]
            updatedGiftArr[index].giftName = editedText[0]
            updatedGiftArr[index].giftLink = editedText[1]
            updatedGiftArr[index].giftCost = editedText[2]
            if (editedText[0] === '') {
                updatedGiftArr[index].giftName = name
            }
            if (editedText[1] === '') {
                updatedGiftArr[index].giftLink = link
            }
            if (editedText[2] === '') {
                updatedGiftArr[index].giftCost = cost || '0'
            }

            await updateDoc(eventRef, {
                gifts: (
                    updatedArr
                )
            });
        }

    }

    const updateClaimed = async () => {
        // await updateDoc(docRef, {
        //     gifts: [...giftArray]
        // }) // Bad!~ this took the filtered array and updated the entire array based on the filtered array!
        try {
            const docRef = doc(db, "events", eventId);
            let combinedArrays = [...giftArray, ...originalGiftArray] // the filterable array and the ENTIRE (original) gift array
            let filterOutPriorGiftStatus = [...new Set(combinedArrays)]
            await updateDoc(docRef, {
                gifts: [...filterOutPriorGiftStatus]
            });
            return true;
        } catch (error) {
            if (error) {
                console.error(error);
                return false;
            }
        }
    }
    function testing() {
        console.log(giftArray);
    }

    const handleClaim = async (e) => {
        // console.log(e.target.id)
        const giftArrId = e.target.dataset.giftarrid;
        console.log(giftArrId);
            return Swal.fire({
                title: 'Would you like to claim this gift?',
                confirmButtonColor: '',
                showCancelButton: true,
                cancelButtonColor: 'gray',
            })
            .then( async (result) => {
            if (result.isConfirmed) {

                setChecked(current => !current); // re-render

                // giftArray[e.target.id].claimed = true
                // giftArray[e.target.id].claimee = user.email
                giftArray[giftArrId].claimed = true
                giftArray[giftArrId].claimee = user.email

               updateClaimed()
               setIsClaimed(true)
            } else {
                return
            }
            // if (giftArray[e.target.id].splittable === false || giftArray[e.target.id].splittable === undefined) { // original
            if (giftArray[giftArrId].splittable === false || giftArray[giftArrId].splittable === undefined) {

            const { value: split } = await Swal.fire({
                    title: `${user?.displayName ? user?.displayName : user.email} has claimed this gift!`,
                    confirmButtonColor: '',
                    confirmButtonText: 'Proceed',
                    text: 'Allow others to split? (leave box unchecked if not)',
                    input: 'checkbox',
                    inputValue: 0
                })
                if (split) {
                    giftArray[giftArrId].splittable = true;
                    // giftArray[e.target.id].splittable = true; // original
                    // console.log(split, typeof split);
                    setSplittable(true);
                    updateClaimed();
                }
            }
            })
            .catch((error) => console.log(error))
        }
    const handleUnclaim = (e) => {
        const giftArrId = e.target.dataset.giftarrid;
        let msg;
        switch
            (giftArray[giftArrId].splittable) {
            // (giftArray[e.target.id].splittable) {
                case true:
                    msg = '(This will remove all splittees)'
                    break;
                default:
                    msg = ''
            }
        return Swal.fire({
            title: 'You have this gift claimed, would you like to unclaim it?',
            html: `${msg}`,
            showCancelButton: true,
            confirmButtonColor: ''
        })
        .then((result) => {
            if (result.isConfirmed) {

                // const filterOutClaimee = claimeeArray[e.target.id].filter(i => i === user.email)
                // console.log(filterOutClaimee);

                // giftArray[e.target.id].claimee = ''; // this needs to change to an updated array filtering out the unclaimer
                // giftArray[e.target.id].claimed = false;
                // giftArray[e.target.id].splittable = false;
                // giftArray[e.target.id].splittees = '';
                giftArray[giftArrId].claimee = ''; // this needs to change to an updated array filtering out the unclaimer
                giftArray[giftArrId].claimed = false;
                giftArray[giftArrId].splittable = false;
                giftArray[giftArrId].splittees = '';

                updateClaimed()
                setIsClaimed(false)
                setNotChecked(current => !current)
            } else {
                return;
            }
            Swal.fire({
                title: `You, "${user.email}" have unclaimed this gift!`,
                confirmButtonColor: ''
            })
        })

    }
    const handleSplit = async (e) => {
        console.log(e.target.id);
        const splitArrId = e.target.dataset.splitarrid.slice(5);
        console.log(splitArrId);
        // let num = parseInt(e.target.id.slice(5))
        // console.log(num);
        // const claimee = giftArray[num]?.claimee
        // let existingSplittees = giftArray[num]?.splittees
        const claimee = giftArray[splitArrId].claimee;
        let existingSplittees = giftArray[splitArrId].splittees;

        if (user.email === claimee) {
            return await Swal.fire({
                title: 'You are the initial item claimee. To remove the option to split with others, unclaim this item!',
                icon: 'warning',
                confirmButtonColor: '',
                confirmButtonText: 'Confirmed'
            })
        } else if (existingSplittees?.includes(user.email)) {
            const { value: remove } = await Swal.fire({
                title: 'You are already on the splittee list, would you like to remove yourself from this list?',
                confirmButtonColor: '',
                showCancelButton: true,
                input: 'checkbox',
                inputValue: 0,

            })
            if (remove === 1) {
                const filterYourselfOut = existingSplittees.filter(i => i !== user.email)
                console.log(filterYourselfOut);
                // giftArray[num].splittees = filterYourselfOut; // original
                giftArray[splitArrId].splittees = filterYourselfOut;
                updateClaimed();
            }
        } else {
            const { value: confirmSplit } = await Swal.fire({
                title: `${claimee} has claimed this item and is allowing for others to split.`,
                html: `Would you like to split this item with ${claimee} and potential others?`,
                confirmButtonColor: '',
                input: 'checkbox',
                inputValue: 0,
                showCancelButton: true

            })
            if (confirmSplit) {
                let arr = [];

                console.log(existingSplittees);
                if (existingSplittees !== undefined && existingSplittees !== '') {
                    // arr.push(...giftArray[num]?.splittees, user.email) // original
                    arr.push(...giftArray[splitArrId]?.splittees, user.email)
                } else {
                    arr.push(user.email)
                }


                giftArray[splitArrId].splittees = arr;
                console.log(giftArray[splitArrId]?.splittees);
                // giftArray[num].splittees = arr; // original
                // console.log(giftArray[num]?.splittees); // original
                updateClaimed();
            }
        }
    }
    const handleToggle = () => {
        setToggle(prev => !prev)
    }

    const claimedInfo = async (claimee, splittees) => {
        // console.log(claimee);
        await Swal.fire({
            title: 'This will reveal who has claimed this item!',
            text: 'Are you sure you want to proceed?',
            confirmButtonColor: 'crimson',
            showCancelButton: true,
            cancelButtonColor: 'grey'
        })
        .then((result) => {
            if (result.isConfirmed && !claimee) {
                Swal.fire({
                    title: `This item has yet to be claimed!`,
                    confirmButtonColor: 'crimson'
                })
            } else if (result.isConfirmed) {
                Swal.fire({
                    title: `Item Claimee(s): ${splittees?.length >= 1 ? claimee + ', ' : claimee} ${splittees === undefined || splittees === '' ? '' : splittees?.map(i => i).join(', ')} `,
                    confirmButtonColor: 'crimson'
                })
            } else {
                return
            }
        })
    }
    const giftInfo = async (index) => { // replaced by dialog component 08 24 2024
        const giftInfo = giftArray[index].giftInfo
        const giftPrice = giftArray[index].giftCost

        const { value: text } = await Swal.fire({
            input: 'textarea',
            inputLabel: 'Item details e.g. size, color, quantity...',
            color: 'black',
            inputPlaceholder: 'Item Info...',
            html: `Current Gift Info: ${giftInfo ? `"${giftInfo}"` : '"None",'} \n Est. Price: ${giftPrice ? giftPrice : '"None entered."'}`,
            showCancelButton: true,
            cancelButtonColor: '',
            confirmButtonColor: ''
        })
        if (text) {

            giftArray[index].giftInfo = text
            // console.log(text);

            Swal.fire({
                title: 'Gift Information: ',
                text: `${text}`,
                confirmButtonColor: ''
            })
            .then(() => updateClaimed())
            .catch(err => err)
        } else {
            return
        }
    }
    const viewGiftInfo = (index) => { // replaced by dialog component 08 24 2024
        const giftInfo = giftArray[index].giftInfo;
        const giftPrice = giftArray[index].giftCost;

        Swal.fire({
            inputPlaceholder: 'Item Info...',
            html: `Current Gift Info:<br /><p> ${giftInfo ? `"${giftInfo}"` : '"None",'} \n </p> Est. Price: ${giftPrice ? giftPrice : '"None entered."'}`,
            showCancelButton: true,
            cancelButtonColor: 'grey',
            confirmButtonColor: 'crimson'
        });
    }

    return (
        <>
            <div className='hidden giftContainer w-full md:flex items-center justify-center flex-col mb-24'>
                <table className='table table-zebra w-[98%] sm:w-3/4 border border-primary text-sm my-2' id="">
                    <tbody>
                        {giftArray.length !== 0 && <tr className='border border-secondary ' id="">
                            <th className='font-bold'>Gift</th>
                            <th className='font-bold pl-0 pr-0 sm:pr-6'>Link</th>
                            <th className='font-bold  lg:pr-24'>User</th>
                            <th className='font-bold text-center' colSpan={2}>Info</th>
                        </tr>}
                        {giftArray && giftArray.map((i, index) =>
                            <tr key={i.giftName + inc++} className={"w-full min-h-36"}>
                                    <td className="text-sm sm:text-base">
                                        <div className='flex flex-row items-center gap-2'>
                                            {
                                                user.email === i.requestor
                                                ? <UpdateGiftInfo index={index} giftArray={giftArray} updateClaimed={updateClaimed} />
                                                // ? <div onClick={() => giftInfo(index)}><BsInfoCircle className='mr-1 text-info' size={'17px'} /></div>
                                                // : <div onClick={() => viewGiftInfo(index)}><BsInfoCircle className='mr-1 text-info' size={'17px'} /></div>
                                                : <ViewGiftInfoDialog index={index} giftArray={giftArray} />
                                            }
                                            {<p className='w-full'>{i.giftName} - {i?.giftCost}$</p>}
                                            {/* {<p className='w-full' id={`${index}`}>{i.giftName} - {i?.giftCost}$</p>} original */}
                                            <div className='editSpan ml-auto' >
                                                {user.email === i.requestor && <FaRegEdit onClick={e => handleEdit(e, index)} className='w-6 h-6 ml-auto text-info hover:cursor-pointer' />}
                                            </div>
                                        </div>
                                    </td>

                                <td className='giftLinkTd w-8 p-0 py-1'>
                                    {i.giftLink !== '' &&
                                        <a rel="noopener noreferrer" href={formatGiftLink(i.giftLink)} target="_blank">
                                            <HiOutlineExternalLink className='text-info w-fit' size={'25px'} />
                                        </a>
                                    }
                                </td>
                                {/* user name */}
                                <td className="requesteeTd w-12 p-0 py-1">
                                    <div className='flex flex-col' id="imgDiv">
                                        <div className='text-xs sm:text-sm '>
                                            {
                                                user.email === i.requestor
                                                ? "You"
                                                : i?.username
                                                ? i.username
                                                : i.requestor.split('@')[0]
                                            }
                                        </div>
                                        {
                                            i?.img
                                            ? <img className='w-10 h-10 sm:w-12 sm:h-12 rounded-full object-cover ml-2' src={i.img} />
                                            : <img className='w-12 h-12 rounded-full object-cover ml-2' src={blankProfilePic} />
                                        }
                                    </div>
                                </td>
                                {/* claim item */}
                                <td className='cbtd w-4 p-2'>
                                    {
                                        user.email === i.requestor && !toggle
                                        ? <></>
                                        // ? <p className=''>?¿</p>
                                        : i.claimed && i.splittable
                                        ?  <>
                                            <input className='cb checkbox checkbox-primary checkbox-md' id={index} data-giftarrid={index} type="checkbox" value={checked} disabled={i.claimee.includes(user.email) ? false : true} checked={i.claimed} onChange={e => handleUnclaim(e)} />
                                            <div className="splitLink w-16 z-50" data-splitarrid={`split${index}`} onClick={e => handleSplit(e)} id={'split' + index}>
                                                <MdCallSplit className='pointer-events-none w-6 h-6' color={'crimson'} />
                                            </div>
                                           </>
                                        : i.claimed && !i.splittable
                                        ? <input className='cb checkbox checkbox-primary checkbox-md' id={index} data-giftarrid={index} type="checkbox" value={checked} disabled={i.claimee.includes(user.email) ? false : true} checked={i.claimed} onChange={e => handleUnclaim(e)} />
                                        : <input className='cb checkbox checkbox-primary checkbox-md' id={index} data-giftarrid={index} type="checkbox" value={notChecked} checked={i.claimed} onChange={e => handleClaim(e)} />
                                    }

                                </td>
                                <td className='infoButton w-4 p-2'>
                                    {<a onClick={() => claimedInfo(i.claimee, i.splittees)} className='hover:cursor-pointer'>
                                        <BsEyeFill className='text-error w-6 h-6' />
                                        {/* <BsInfoCircle className='ml-auto text-info mr-1' size={'20px'} /> */}
                                    </a>}
                                </td>
                            </tr>
                        )}
                        {/* <EmptyGiftAndSearch giftArray={giftArray} inFocus={inFocus} /> */}
                    </tbody>
                </table>
                {/* <GiftStatusToggle toggle={toggle} handleToggle={handleToggle} giftArray={giftArray} /> */}

            </div>

            <div id="mobileCardsContainer" className='flex md:hidden items-center justify-center flex-col gap-y-10 w-full px-4 mb-10'>
                {giftArray.length && giftArray.map((gift, index) => {
                    return (
                            <div key={gift.giftName + index} className="card bg-base-100 w-full shadow-xl">
                                <div className="card-body flex flex-col gap-4">
                                    <div className='flex flex-row gap-2 items-center'>
                                        <div className='text-xl font-bold'>
                                            For:
                                        </div>
                                        <span>
                                            {
                                                user.email === gift.requestor
                                                ? "You"
                                                : gift?.username
                                                ? gift.username
                                                : gift.requestor.split('@')[0]
                                            }
                                        </span>
                                        <span className='absolute right-5'>
                                            {
                                                gift?.img
                                                ? <img className='w-10 h-10 sm:w-12 sm:h-12 rounded-full object-cover ml-2' src={gift.img} />
                                                : <img className='w-12 h-12 rounded-full object-cover ml-2' src={blankProfilePic} />
                                            }
                                        </span>
                                    </div>
                                    <h3 className="card-title">
                                        Gift: <span className='text-sm'>{gift.giftName}</span>
                                    </h3>
                                        <div className='flex flex-row items-center gap-2'>
                                            {gift.giftLink !== '' &&
                                                <a rel="noopener noreferrer" href={formatGiftLink(gift.giftLink)} target="_blank">
                                                    <HiOutlineExternalLink className='text-info w-fit' size={'25px'} />
                                                </a>
                                            }
                                            {<p className='w-full text-success text-lg font-bold italic' id={`mobile${index}`}>{gift?.giftCost}$</p>}
                                        </div>
                                        <div className='flex flex-row items-center justify-center gap-2'>
                                            {user.email === gift.requestor
                                                ? <UpdateGiftInfo index={index} giftArray={giftArray} updateClaimed={updateClaimed} />
                                                : <ViewGiftInfoDialog index={index} giftArray={giftArray} />
                                            }
                                            <p>View Info</p>
                                        </div>

                                        <div className='flex flex-row gap-2' >
                                            <div className='hover:cursor-pointer'>
                                                {user.email === gift.requestor && <FaRegEdit onClick={e => handleEdit(e, index)} className='w-6 h-6 text-info' />}
                                            </div>
                                            <div>
                                                Edit gift info
                                            </div>
                                        </div>
                                        <div className='flex flex-row gap-2'>
                                            <div>
                                                {<a onClick={() => claimedInfo(gift.claimee, gift.splittees)} className=''>
                                                    <BsEyeFill className='text-error w-6 h-6' />
                                                </a>}
                                            </div>
                                            <div>
                                                <p>Reveal claim status</p>
                                            </div>
                                        </div>

                                    <div className="card-actions justify-end">
                                        {/* <button className="btn btn-primary">Claim</button> */}

                                        <div className='flex flex-col gap-y-3'>
                                            {
                                                user.email === gift.requestor && !toggle
                                                ? <></>
                                                // ? '?¿'
                                                : gift.claimed && gift.splittable
                                                ?  <>
                                                    <div className='border border-secondary rounded-md p-4 flex flex-col gap-4'>
                                                        <div className='flex flex-row gap-2 items-center'>
                                                            <span>Claim Item</span>
                                                            <input className='cb checkbox checkbox-primary checkbox-lg' data-giftarrid={index} id={`${index}`} type="checkbox" value={checked} disabled={gift.claimee.includes(user.email) ? false : true} checked={gift.claimed} onChange={e => handleUnclaim(e)} />
                                                        </div>
                                                        <div className='flex flex-row items-center'>
                                                            <span>Split item cost</span>
                                                            <div className="z-50 cursor-pointer" onClick={e => handleSplit(e)} data-splitarrid={`split${index}`} id={'mobilesplit' + index}>
                                                                <MdCallSplit className='pointer-events-none w-8 h-8' color={'crimson'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                  </>
                                                : gift.claimed && !gift.splittable
                                                ?   <div className='flex flex-row gap-2 border border-secondary rounded-md p-4 items-center'>
                                                        <span>Claim Item</span>
                                                        <input className='cb checkbox checkbox-primary checkbox-lg' data-giftarrid={index} id={`${index}`} type="checkbox" value={checked} disabled={gift.claimee.includes(user.email) ? false : true} checked={gift.claimed} onChange={e => handleUnclaim(e)} />
                                                    </div>
                                                :
                                                <div className='flex flex-row gap-2 border border-secondary rounded-md p-4 items-center'>
                                                    <span>Claim Item</span>
                                                    <input className='cb checkbox checkbox-primary checkbox-lg' data-giftarrid={index} id={`${index}`} type="checkbox" value={notChecked} checked={gift.claimed} onChange={e => handleClaim(e)} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                    );
                })}
            </div>
            <div className='flex flex-col items-center justify-center'>
                <EmptyGiftAndSearch giftArray={giftArray} inFocus={inFocus} />
                <GiftStatusToggle toggle={toggle} handleToggle={handleToggle} giftArray={giftArray} />
            </div>

        </>
    )
}