import { useLocation, Link } from "react-router-dom";

export default function CookieCrumbs()
{
    const params = useLocation();
    const pathName = params.pathname;
    // console.log(pathName);
    const location = useLocation();
    // console.log(location.pathname);

    let eventId;

//   const pathName = usePathname();
//   useEffect(() => {
    const breadCrumb = pathName.split("/");
    let crumbsArr = [];
    // console.log(pathName);
    // console.log(breadCrumb);
        if (breadCrumb[breadCrumb.length - 1] === "") {
            breadCrumb.pop()
        }
        else if (breadCrumb[2] === 'eventinfo') {
          breadCrumb.pop();
        } else if (breadCrumb[2] === 'event') {
          breadCrumb.pop();
        } else if (breadCrumb[2] === 'user') {
          breadCrumb.pop();
        }
        let poppedCrumb = breadCrumb.pop();
        if (poppedCrumb === 'eventinfo') {
          poppedCrumb = 'Event Info'
        } else if (poppedCrumb === 'dashboard') {
            poppedCrumb = 'Dashboard'
        } else if (poppedCrumb === 'addeventform') {
          poppedCrumb = 'Add Event Form'
        } else if (poppedCrumb === 'event') {
          poppedCrumb = 'Event'
        } else if (poppedCrumb === 'departure') {
          poppedCrumb = 'Departure'
        } else if (poppedCrumb === '') {
          poppedCrumb = 'Login'
        } else if (poppedCrumb === 'user') {
          poppedCrumb = 'User Profile Page'
        } else if (poppedCrumb === 'about') {
          poppedCrumb = 'About'
        } else if (poppedCrumb === 'oauth') {
            poppedCrumb = 'OAuth Login'
        } else if (location.pathname === "/") {
          breadCrumb[0] = '/dashboard'
        } else if (poppedCrumb === 'resetpassword') {
          poppedCrumb = 'Reset Password'
        }

        crumbsArr.push(...breadCrumb);
        // console.log(crumbsArr);
        // if (crumbsArr[crumbsArr.length -1] === '') {
        //     crumbsArr = crumbsArr.pop()
        //     console.log('c array', crumbsArr);
        // }
        if (crumbsArr[0] === '' && crumbsArr[1] === '') {
        crumbsArr = crumbsArr.slice(0,1);
        }
        if (crumbsArr[2] === 'event') {
            eventId = crumbsArr[3]
            crumbsArr = crumbsArr.slice(0, 3)
        }
        // console.log('crumbsArr ', crumbsArr);
//   }, [])

  return (
    <>
      <div className='w-fit mx-auto my-5 flex items-center justify-center breadcrumbs border-4 border-primary rounded-2xl px-5'>
        <ul>
          {crumbsArr.map((crumb, i) => {
            let arr = [];
            arr.push(crumb)
            // console.log(crumbsArr.indexOf(crumb));

            // console.log(arr);
            // console.log(crumbsArr.length);
            if (crumb === 'eventmanagement') {
              crumb = 'Event Management';
            } else if (crumb === 'createevent') {
              crumb = 'Create Event'
            } else if (crumb === 'addeventform') {
                crumb = 'Add New Event'
            } else if (crumb === 'eventinfo') {
                crumb = 'Event Statistics'
            } else if (crumb === 'dashmanagement') {
                crumb = 'Manage'
            } else if (crumb === 'event') {
                crumb = 'Event'
            } else if (crumb === 'oauth') {
              crumb = 'O Auth Providers'
            }


            return (
            //   <li key={i}><a href={`/${crumb}`}>{crumb === '' ? 'Home' : crumb = crumb[0].toUpperCase() + crumb.slice(1)}</a></li>
              <li key={i} className="text-sm sm:text-base">

                <Link
                  className="text-info hover:underline"
                  to={crumb === "" ? `/dashboard` : crumb !== 'Event' ? `/${crumb}`
                    : `/dashboard/${crumb.toLowerCase()}/${eventId}`}>{crumb === '' ? 'Home'
                    : crumb = crumb[0].toUpperCase() + crumb.slice(1)

                    }
                </Link>



              </li>


            )
            })}
            <li className="text-sm sm:text-base">
            {poppedCrumb}
            </li>

        </ul>
      </div>
    </>
  );
}