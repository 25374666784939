import Card from "../dashcard/Card";

// import flowers from '../../app/images/flowers.jpg';
// import balloons from '../../app/images/balloons.jpg';
import flowers from '../../images/flowers.jpg';
import balloons from '../../images/balloons.jpg';
import { useEffect, useState } from "react";
import { auth } from "../../firebase";



export default function DashOptions()
{
    const [user] = useState(auth.currentUser)
    const cardData = [
        {
            image: balloons,
            title: 'Create a new Event or view Existing events',
            info: 'Start something new and invite others to join, or continue participating!',
            href: '/dashboard/addeventform',
            alt: "Party Balloons"
        },
        // {
        //     image: flowers,
        //     title: 'View existing events',
        //     info: 'Keep track of your current and upcoming events here',
        //     href: '',
        //     alt: "Party Flowers"
        // },
        // {
        //     image: flowers,
        //     title: 'Manage events',
        //     info: 'Remove events, edit event information,',
        //     href: '/dashboard/eventmanagement',
        //     alt: "Party Flowers"
        // },
        {
            image: flowers,
            title: 'Edit your profile',
            info: 'Change your profile picture, add or change existing username',
            href: `/dashboard/user/${user.uid}`,
            alt: "Party Flowers"
        }
    ];

    useEffect(() => {
        // console.log(user);
    }, [])

    return (
        <>
            {/* <div className="w-full border-opacity-50 md:col-start-2 md:col-end-3">
                <div className="grid h-20 card bg-base-300 rounded-box place-items-center text-2xl lg:grid-cols-3 grid-cols-2 ">
                    <h2 className="row-start-1 row-end-1 lg:text-center italic col-start-1 col-end-2 lg:col-start-2">Dashboard</h2>
                    <img className="row-start-1 row-end-1 ml-auto mr-2 italic col-start-2 col-end-3 lg:col-start-3 rounded-full avatar aspect-square shrink-0 grow-0" width={70} height={70} src={!user.img ? flowers : user.img} alt='User Avatar' />

                </div>
                <div className="divider">Welcome {user.email}!</div>
                <div className="grid h-20 card bg-base-300 rounded-box place-items-center text-lg">
                    <h2 className="text-center">There are no new changes to any of your events...</h2>
                </div>
            </div> */}
            <div className="">
                <ul className="flex flex-row flex-wrap items-center justify-center">
                    {cardData.map((card) => {
                        return (<li key={card.title} className="p-2">
                            <Card img={card.image} title={card.title} info={card.info} href={card.href} alt={card.alt} />
                        </li>)
                    })}
                </ul>
            </div>
        </>
    );

}