import { useRef} from "react";
import { BsInfoCircle } from 'react-icons/bs';


const ViewGiftInfoDialog = ({ index, giftArray }) => {
    const giftInfo = giftArray[index].giftInfo;
    const giftPrice = giftArray[index].giftCost;
    const dialogRef = useRef();

    return (
        <>
            <div className="text-info cursor-pointer" onClick={()=>dialogRef.current.showModal()}><BsInfoCircle /></div>
            <dialog ref={dialogRef} className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Gift Info:</h3>
                    <div>
                        <ul>
                            <li className={`text-md indent-5 italic ${giftInfo ? "" : "text-warning"}`}>Details:&nbsp;{giftInfo ? giftInfo : "No gift information has been entered..."}</li>
                            <li className="text-md indent-5 italic">Price:&nbsp;&nbsp;&nbsp;<span className="font-bold text-green-700">{giftPrice}&nbsp;$</span> </li>
                        </ul>
                    </div>

                    <div className="modal-action">
                        <form method="dialog">
                            <button className="btn">Close</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </>
    );
}

export default ViewGiftInfoDialog;

// if (text) {

//     giftArray[index].giftInfo = text
//     // console.log(text);

//     Swal.fire({
//         title: 'Gift Information: ',
//         text: `${text}`,
//         confirmButtonColor: ''
//     })
//     .then(() => updateClaimed())