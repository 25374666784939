import { unlink, updatePassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useRef, useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'






export default function ChangePassword( { modalRef }) {

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const [reveal, setReveal] = useState(false);
  const [matchError, setMatchError] = useState(false)
  const [lengthError, setLengthError] = useState(false)
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [authError, setAuthError] = useState(false);
  const submitBtnRef = useRef();
  const [spinner, setSpinner] = useState('hidden')
  // console.log(submitBtnRef.current.disabled);


const handleSubmit = e => {
  e.preventDefault();


  if (password !== confirmPassword) {
    setMatchError(true)
    setTimeout(() => {
      setMatchError(false)
    }, 3000);
    return
  } else if (lengthError) {
    // setMatchError(true)
    setLengthError(true)
    setTimeout(() => {
      setMatchError(false)
    }, 3000);
    return
  } else if (password === confirmPassword && password.length >= 6 && confirmPassword.length >= 6) {
    submitBtnRef.current.disabled = true;
    setSpinner('loading loading-spinner')
    updatePassword(auth.currentUser, password)
      .then(() => {
        // console.log('Password successfully updated');
        setUpdateSuccess(true);
        setSpinner('hidden')
        passwordRef.current.value = '';
        confirmPasswordRef.current.value = '';


        setTimeout(() => {
          setUpdateSuccess(false);
          submitBtnRef.current.disabled = false;
          setReveal(false)
          passwordRef.current.type = "password"
          confirmPasswordRef.current.type = "password"
          modalRef.current.close()
        }, 4500)

      }).catch(error => {
        setSpinner('hidden');
        submitBtnRef.current.disabled = false;
        passwordRef.current.value = '';
        confirmPasswordRef.current.value = '';
        console.log(error);
        console.log(error.code);
        if (error.code === 'auth/requires-recent-login') {
          setAuthError(true)
          setTimeout(() => {
            setAuthError(false)
          }, 4500)
        }

      })
  }
}

const handleReveal = () => {
  if (passwordRef.current.type === "password" && confirmPasswordRef.current.type === "password") {
    setReveal(true)
    passwordRef.current.type = "text"
    confirmPasswordRef.current.type = "text"
  } else {
    setReveal(false)
    passwordRef.current.type = "password"
    confirmPasswordRef.current.type = "password"
  }
}

    return (
        <>
            <h1 className="text-2xl italic text-primary text-center">Change Password:</h1>
          <div className="flex items-center flex-col justify-center p-4 w-full">
            <div className="text-accent" onClick={handleReveal}>{reveal ? <AiFillEye className="w-8 h-8" /> : <AiFillEyeInvisible className="w-8 h-8" />}</div>
              <div className="flex flex-col my-2">

                <label className="w-full" htmlFor="New Password">New Password</label>
                <div className={` ${lengthError ? 'tooltip tooltip-open tooltip-top tooltip-warning font-bold' : ''}`} data-tip="6 character min.">
                  <input className="input input-primary input-bordered w-[300px] " name="New Password" ref={passwordRef} minLength={6} required onChange={e => setPassword(e.target.value)} type="password" />
                </div>
              </div>
              <div className="flex flex-col my-2">
                <label htmlFor="Confirm New Password">Confirm New Password</label>
                <input className="input input-primary input-bordered w-[300px] " name="Confirm New Password" ref={confirmPasswordRef} minLength={6} required onChange={e => setConfirmPassword(e.target.value)} type="password" />
              </div>
                <div className={`${matchError ? 'tooltip tooltip-open tooltip-bottom tooltip-error font-bold' : ''}`} data-tip="Passwords must match!">
                  <button className="btn btn-large btn-secondary" ref={submitBtnRef} onClick={handleSubmit}>Update Password <span className={`${spinner}`}></span></button>
                </div>

                <div className={`${updateSuccess ? 'toast-start toast-bottom block' : 'hidden'}`}>
                  <div className="alert alert-success">
                    <span className="font-bold">Password updated successfully!</span>
                  </div>
                </div>

                <div className={`${authError ? 'toast-start toast-bottom block my-4 absolute' : 'hidden'}`}>
                  <div className="alert alert-error">
                    <span className="font-bold">Resetting your password requires a recent login in, please re-log in and try again!</span>
                  </div>
                </div>

          </div>
        </>
    )
}