import { useState } from "react";
import { doc, updateDoc } from 'firebase/firestore';
import { db, auth } from "../../firebase";
import './updateusername.css';
import { useNavigate } from "react-router-dom";
import { updateProfile } from "firebase/auth";


export default ({ userId, userInfo }) => {
    let [userName, setUserName] = useState('');
    let [updating, setUpdating] = useState('hidden');
    const navigate = useNavigate();
    const delay = t => new Promise(resolve => setTimeout(resolve, t));


    const handleSubmit = async e => {
        e.preventDefault();
        setUpdating('loading loading-spinner')

        if (userName === '') return navigate('/dashboard')

        const userRef = doc(db, 'users', userId)

        await updateDoc(userRef, {
            username: userName,
        })
        await updateProfile(auth.currentUser, {
            displayName: userName
        })
        setUserName('');
        delay(500)
            .then(() => navigate('/dashboard'))

    }

    return (
        <>
            <div className="usernameFormContainer">
                <form onSubmit={handleSubmit}>
                    <div>{!userInfo.username ? <>Add or </> : ""}Update username:</div>
                    <input
                        className="input input-bordered input-success mt-1"
                        type="text"
                        placeholder="enter username"
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                    />
                    <button className={`btn btn-circle btn-success hover:bg-success-content hover:text-base-100 mt-2`} type="submit">Save & Return<span className={`${updating}`}></span></button>
                </form>
            </div>
        </>
    );
}