import { useEffect, useState } from "react";
import { getDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from '../../firebase';
import { HiOutlineColorSwatch } from 'react-icons/hi'


export default function ColorScheme() {
    const [choice, setChoice] = useState(null);
    const userRef = doc(db, 'users', auth?.currentUser?.uid);

    async function updateUserInfo(newChoice) {
        await updateDoc(userRef, {
            themePreference: newChoice
        })
    }

    const handleChange = e => {
        setChoice(e.target.value);

        updateUserInfo(e.target.value);
        document.querySelector('html').dataset.theme = e.target.value;

    }

    useEffect(() => {
    }, [choice])



    return (
        <>
        <div className="flex flex-col mr-4 border border-info p-2 rounded-lg w-24 sm:w-auto">
            <div className="flex items-center justify-center">
                <HiOutlineColorSwatch className="mr-2 w-16 h-16 text-secondary animate-pulse" />
            </div>
        <label className="my-2 font-bold text-xl text-accent italic">Theme:</label>
            <select className="select p-0 sm:pr-10 sm:pl-4" onChange={handleChange}>
                <option disabled hidden>Select</option>
                <option value="light">light</option>
                <option value="dark">dark</option>
                <option value="cyberpunk">cyberpunk</option>
                <option value="luxury">luxury</option>
                <option value="lofi">lofi</option>
                <option value="coffee">coffee</option>
                <option value="pastel">pastel</option>
            </select>
        </div>
        </>
    );
}