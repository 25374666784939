// import './loggedout.css';
import { HiOutlineLogin } from 'react-icons/hi';
import { BiMailSend } from 'react-icons/bi'
import { Link } from 'react-router-dom';
import { RiMailSendLine } from 'react-icons/ri'
import { TbLogin } from 'react-icons/tb'

const MailTo = ({ mailto }) => {
    return (
        <Link
            to={'#'}
            onClick={e => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            <RiMailSendLine className='text-info cursor-pointer w-16 h-16 ml-4 hover:text-secondary' /></Link>
    )
}





export default () => {
    return (
        <>
            <div className='flex items-center justify-items-start flex-col px-4'>
                <div className=''>
                    <h1 className='my-2 text-xl text-center'>Thanks for visiting Claims Gifts!</h1>
                    <h2 className='my-2'>You have successfully logged out...</h2>
                    <h4 className='my-2 italic flex items-center '>
                        Questions, concerns?
                        <span className='ml-auto'>
                            <MailTo mailto='mailto:claimsgifts@gmail.com' />
                        </span>
                    </h4>
                    <h4 className='my-2 flex items-center w-full'>
                            Return to Login Page
                        <span className='ml-auto'>
                            <Link to={'/'}>
                                <TbLogin className='text-info cursor-pointer w-16 h-16 hover:text-secondary' />
                            </Link>
                        </span>
                    </h4>
                </div>
            </div>
        </>
    )
}