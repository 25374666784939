export default function WhomToThank ({ data }) {

    return (
        <>
            <div className="flex flex-col items-center justify-center p-4">
                <h1 className="text-2xl text-primary">Who to Thank:</h1>
                <ul>
                    {data?.map((thankee, i) => {
                        return (
                        <li key={i}>
                            <span className="font-bold text-lg">
                        {thankee.claimee}</span> gifted you <span className="italic font-bold text-lg">{thankee.giftName}!</span>
                        </li>)
                    })}
                </ul>
            </div>
        </>
    )
}