


export default () => {
    return (
        <>
            <div className="aboutTainer flex items-center justify-center flex-col w-full px-5 sm:w-3/4 lg:w-1/2 mx-auto">
                <h1 className="my-4 text-2xl">The Mission</h1>
                <p className="indent-10">
                    Our mission is to simplify the gift and item exchange process for holidays and events, making celebrations smoother and more enjoyable for everyone involved.
                </p>
                <h1 className="my-4 text-2xl">How?</h1>
                <p className="indent-10">
                    We achieve this by consolidating event coordination, planning, and communication into one central location, allowing you to focus on what truly matters—creating memorable moments.
                </p>
                <h1 className="my-4 text-2xl">What We Offer</h1>
                <p className="indent-10">
                    Our application goes beyond the traditional gift registry. It's a versatile tool that can be adapted to <strong>any kind of gathering</strong>, whether you're hosting a potluck, planning a BBQ, organizing a baby shower, or preparing for any event that brings people together. By streamlining the process, we help you keep track of contributions, manage invites, and ensure that every detail is covered, so you can celebrate with ease.
                </p>
            </div>
        </>
    )
}
