import { getAuth } from "firebase/auth";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react"
import { BsBoxArrowInRight, BsCardChecklist } from "react-icons/bs";
import { HiOutlineExternalLink } from "react-icons/hi";
import { Link, useParams } from "react-router-dom"
import Swal from "sweetalert2";
import { auth, db } from "../../firebase";
import { BsInfoCircle } from 'react-icons/bs';
import { MdCallSplit } from 'react-icons/md'
import { MdRestoreFromTrash } from "react-icons/md";
import xlsx from "json-as-xlsx";
import WhomToThank from "../whomtothank/WhomToThank";
import { SiMicrosoftexcel } from "react-icons/si";






export default () => {
    const params = useParams();
    let loc = params.event;
    const [eventId] = useState(loc);
    const [eventsData, setEventsData] = useState([]);
    const [separateGifts, setSeparateGifts] = useState([]);
    const [selection, setSelection] = useState('All');
    const [disableThank, setDisableThank] = useState(true);
    const [countdown, setCountdown] = useState("");
    const [loading, setLoading] = useState(false);
    const [addedSeparateGift, setAddedSeparateGift] = useState(false);
    const user = auth.currentUser
    const date = eventsData?.events?.eventDate
    const dateEnd = eventsData?.events?.eventEndDate
    const dateFormat = date?.slice(5, 7) + '-' + date?.slice(8, 10) + '-' + date?.slice(0,4)
    const modalRef = useRef();
    const separateItemRef = useRef();
    const [separateItems, setSeparateItems] = useState({});

    const excelSettings = {
        fileName: '',
        writeMode: "writeFile",
        RTL: false,
    };
    const reRenderUI = () => {
        setAddedSeparateGift(prev => !prev);
    }

    useEffect(() => {
        let intervalId;
        const getEventInfo = async () => {
            const arr = [];
            const docRef = doc(db, 'events', eventId)
            const docSnap = await getDoc(docRef)
            if (docSnap?.exists()) {
                const data = docSnap.data();
                excelSettings.fileName = data.events.eventName;
                arr.push(data);
                setEventsData(...arr);
                setSeparateGifts(data.separateGifts);


            const currentDate = new Date();
            const targetDate = new Date(data?.events?.eventEndDate?.split("-").join(" "));

            if (data?.events.eventEndDate === undefined) {
                return
            } else if (currentDate >= targetDate) {
                setDisableThank(prev => !prev);
                setCountdown('Whom To Thank!')
            } else {
                setDisableThank(true);
                // setCountdown(`Who to thank countdown: ${calculateCountdown()}`);
                intervalId = setInterval(() => {
                    setCountdown(`Who to thank countdown: ${calculateCountdown(targetDate)}`);
                }, 1000)
            }
            }
        }
        getEventInfo();

        return () => clearInterval(intervalId);


    }, [addedSeparateGift]);

    const handleSeparatelyPurchasedItemModal = () => {
        separateItemRef.current.showModal();
    }
    const handleSeparateItemsInput = e => {
        setSeparateItems((prev) => {
            return {
                ...prev,
                giftRef: eventId,
                purchaser: user.uid,
                [e.target.name]: e.target.value
            }
        });
        // console.log(separateItems);
    }
    const submitSeparatelyPurchasedItem = async () => {
        const docRef = doc(db, "events", loc)
        setLoading(true)
        try {
            await updateDoc(docRef, {
                separateGifts: arrayUnion(separateItems)
            });
            setLoading(false);
            separateItemRef.current.close();
            document.querySelectorAll('input').forEach(i => i.value = '');
            reRenderUI();

        } catch (error) {
            console.error(error);
            setLoading(false)
        }
    }
    const handleRemoveSeparateItem = async e => {
        try {
            const removeItem = mySeparateGifts[e.target.id];
            const updatedArr = mySeparateGifts.filter((item) => {
                return item !== removeItem;
            });
            const docRef = doc(db, "events", loc);
            await updateDoc(docRef, {
                separateGifts: updatedArr
            });
            reRenderUI();
        } catch (error) {
            console.error(error);
            console.error(error?.message);
        }
    }
    function calculateCountdown(targetDate)
    {
        const currentDate = new Date();
        // const targetDate = new Date(currentDate.getFullYear(), 11, 25);
        // const targetDate = targetedDate;
        const timeDifference = targetDate?.getTime() - currentDate?.getTime();

        const days =    Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours =   Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        return `${days}:${hours}:${minutes}:${seconds}`;
    }

    const itemsClaimed = eventsData?.gifts // i believe the solution is to pass one arg, and filter the preferred arg 4:00pm
    // const itemsClaimed = eventsData?.gifts?.filter(i => i.claimee === user?.email) // i believe the solution is to pass one arg, and filter the preferred arg 4:00pm
    const whatsClaimed = itemsClaimed?.filter(i => i?.claimee === user?.email)

    const whatIclaimed = [...new Set(whatsClaimed?.map(i => i.requestor))] // added from local 11/7/2023**********

    const itemsSplit = eventsData?.gifts?.filter(i => i.splittees?.includes(user.email))

    const claimedGuestsList = itemsClaimed?.map(i => i.requestor)
    const s = [...new Set(claimedGuestsList)]

    const userKeys = eventsData?.eventParticipants // all users
    const unAccountedFor = userKeys?.filter(i => !s.includes(i)) // 11/17/2023 WAS: .filter(i => !s.includes(i)) -- didnt work, so removed `!` - why? uncertain
    const peopleYouHaveNotBoughtFor = userKeys?.filter(i => !whatIclaimed.includes(i) && i !== user?.email)

    const whoBoughtMeStuff = eventsData?.gifts?.filter((giftForMe => {
        // return giftForMe.requestor !== user?.email && giftForMe.claimed
        return giftForMe?.claimed && giftForMe.requestor === user?.email
    }));

    const handleChange = (e) => {
        e.preventDefault()
        setSelection(e.target.value)
        // console.log(selection);
    }
    const formatGiftLink = (link) => {
        if (link?.slice(0, 4).toLowerCase() === 'http') {
            return link
        } else {
            return 'https://' + link
        }
    }
    const filterData = (eventsData) => {
        let eD = eventsData?.filter(i => i?.claimee === user?.email)
        let iS = eventsData?.filter(i => i?.splittees?.includes(user?.email))
        // let iSS = eventsData?.filter(i => i?.claimee === user?.email && i?.splittees?.length >= 1)
        // iS.forEach(i => i.giftCost = i.giftCost / (i.splittees.length + 1))
        let combinedArr = []
        eventsData && combinedArr.push(...eD, ...iS)
        // console.log(combinedArr);
        // console.log(eD);
        // console.log(iS);
        let s0 = eventsData?.filter(i => i?.claimee === user?.email)
        let s = s0?.filter((gift) => gift?.requestor === selection)
        if (selection === 'All') {
            return combinedArr;
        } else if (selection === 'individual') {
            // let spl = eventsData?.filter((gift) => gift.splittees !== undefined && gift.splittees !== '' && gift.splittees.includes(user.email))
            return eD;
        } else if (selection === 'splits') {
            // let spl = eventsData?.filter((gift) => gift.splittees !== undefined && gift.splittees !== '' && gift.splittees.includes(user.email))
            return iS;
        } else {
            return s;
        }
    }
    const yourClaimedGifts = (arr) => {
            let eD = arr?.filter(i => i?.claimee === user?.email)
            let iS = arr?.filter(i => i?.splittees?.includes(user?.email))
            let combinedArr = [];
            arr && combinedArr.push(...eD, ...iS);
            return combinedArr;
    }
    const yourSeparateGifts = (arr) => {
        return arr?.filter((gift) => {
            return gift?.purchaser === user?.uid;
        });
    }

    const mySeparateGifts = yourSeparateGifts(separateGifts)
    const separateGiftCosts = () => {
        const costs = mySeparateGifts?.map(i => parseInt(i.giftCost))
        const totals = costs?.reduce((a,b) => a+b, 0)
        return totals ? totals : 0;
    }
    const separateGiftTotals = separateGiftCosts();
    // console.log('separateGiftCosts \t', separateGiftCosts())
    // const xcelGiftData = filterData(itemsClaimed); // items you are getting for people
    const xcelGiftData = yourClaimedGifts(itemsClaimed); // items you are getting for people

    function formatToExcel(sheetTitle, data) {
        const formattedData = [{
            sheet: sheetTitle,
            columns: [
                {label: 'Gift Name', value: 'giftName'},
                {label: 'Gift Link', value: 'giftLink'},
                {label: 'Gift Info', value: 'giftInfo'},
                {label: 'Requestor', value: 'requestor'},
                {label: 'Gift Cost', value: 'giftCost'},
            ],
            content: data
        }];
        return formattedData;
    }


    const xcelData = formatToExcel(eventsData?.events?.eventName, mySeparateGifts ? [...xcelGiftData, ...mySeparateGifts] : [...xcelGiftData]);
    const handleExcelExport = () => {
        xlsx(xcelData, excelSettings);
    }

    const splitteeList = (arr, claimee, i) => {
        let newArr = [...arr, claimee];
        Swal.fire({
            title: `(${newArr.length}) Users splitting item "${i.giftName}":`,
            confirmButtonColor: 'crimson',
            html: `${newArr.map(i => i).join(', ')}<br /><br /> Person in charge of purchasing item: "${claimee}"`,
        })
    }

    // we are leaving off where the filter works, now we need to adjust the price for split items. perhaps another parameter for type of incoming data to adjust sum
    let roundDifference;
    let total;
    const mapIt = (data) => {
        const filt = filterData(data)
        const filtSplittees = filt?.filter(i => i.splittees !== undefined && i.splittees !== '')

        const difference = filtSplittees?.map(i =>
            Math.round(parseInt(i.giftCost ? i.giftCost : 0)) - (Math.round(parseInt(i.giftCost
                ? i.giftCost
                : 0))
                / (i.splittees.length + 1)))
        roundDifference = difference?.reduce((a,b) => a + b, 0) // for split items..

        let sum = filt?.map(i => parseInt(i?.giftCost ? i.giftCost : 0))
        total = sum?.reduce((a, b) => a + b, 0)
        return filt?.map((i, index) =>
                <tr className={index % 2 === 0 ? 'firstRowColor' : ''} key={index} align={"center"}>
                    <td className="itemCol">{i?.giftName}{i?.splittees !== undefined && i?.splittees !== '' && <MdCallSplit color="crimson" size={'25px'} />}</td>
                    <td className="forPersonCol">{i?.username || i?.requestor}</td>
                    <td className="linkCol">{i?.giftLink !== '' && <a href={formatGiftLink(i?.giftLink)} target="_blank" rel="noreferrer">
                        <HiOutlineExternalLink className='text-info w-fit' size={'25px'} /></a>}
                    </td>
                    <td className="costCol">
                        {i?.splittees !== undefined && i?.splittees !== '' && <a onClick={() => splitteeList(i?.splittees, i?.claimee, i)}><BsInfoCircle size={'20px'} /></a>}
                        {i?.splittees !== undefined && i?.splittees !== '' && parseInt(i.giftCost ? i.giftCost : 0) + ' split by (' + (i?.splittees?.length + 1) + ') '}
                        {(selection === 'splits' && i?.splittees !== undefined && i?.splittees !== '') || (selection === 'All' && i?.splittees !== undefined && i?.splittees !== '') || (selection === 'individual' && i?.splittees !== undefined && i?.splittees !== '') || (selection !== null && i?.splittees !== undefined && i?.splittees !== '') ? Math.round((i?.giftCost ? parseInt(i?.giftCost) : 0) / (i?.splittees?.length + 1)) : i?.giftCost ? parseInt(i?.giftCost) : 0}$
                    </td>
                </tr>
            )
    }


    const mapSeparateGifts = (arr) => {
        return arr.map((item, index) => {
            return (
                <tr key={index} align={"center"}>
                    <td>{item.giftName}</td>
                    <td>{item.requestor}</td>
                    <td>{item?.giftLink !== '' && <a href={formatGiftLink(item?.giftLink)} target="_blank" rel="noreferrer"><HiOutlineExternalLink size={'25px'} /></a>}</td>
                    <td>{item.giftCost}</td>
                    <td id={`${index}`} className="hover:text-secondary hover:cursor-pointer w-fit" onClick={handleRemoveSeparateItem}><MdRestoreFromTrash className="w-6 h-6 pointer-events-none" /></td>
                </tr>
            )
        })
    }

    const guestList = () => {
        return Swal.fire({
            title:'Entire Guest List:',
            html:`${userKeys.map((i) => `<p style="margin:3px;">${i}</p>`).join('')} <h2 style="margin:3px; margin-top: 16px; margin-bottom: 20px; font-size: 28px; font-weight: bold;">User gifts unaccounted for:</h2> ${peopleYouHaveNotBoughtFor.map(i => `<p style="margin:3px;"> ${i}</p>`).join('')}`,
            confirmButtonColor:''
        })
    }

    const handleModal = () => {
        modalRef.current.showModal()
    }

    return (
        <>
            <div className="flex flex-col items-center justify-center">
                <h2 className="my-2 text-2xl sm:text-3xl">Event Summary for event: </h2>
                {eventsData && <h5 className="italic font-bold text-xl my-2">"{eventsData.events?.eventName}" on {dateFormat}</h5>}
                {eventsData && <h5 className="my-2">Event created by "{eventsData.events?.eventOwner}"</h5>}
                <div className="my-2">
                    <Link className="flex flex-row items-center text-info hover:text-secondary hover:scale-[115%] hover:duration-200 ease-in-out" to={`/dashboard/event/${loc}`}>
                        Go to this event <BsBoxArrowInRight className="text-info" size={'30px'} />
                    </Link>
                </div>
                <hr />
                <div id="dropDownTainer" className={`my-2`} style={{display: `${whatsClaimed?.length !== 0 || itemsSplit.length !== 0 ? '' : 'none'}`}}>
                    <label className="whiteBgOpacity">Filter by: </label>
                    <select className="select border border-base-300 mx-1" onChange={e => handleChange(e)}>
                        <optgroup label="Users">
                            <option value="All">All gift claims</option>
                            <option value="individual">Your claim purchases</option>
                            {eventsData
                                ?.eventParticipants
                                ?.map(i => <option key={i} value={`${i}`}>{i}</option>)
                            }
                            <option value="splits">Items you are splitting</option>
                        </optgroup>
                    </select>
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center py-2 gap-4 hover:text-secondary hover:scale-[115%] hover:duration-200 ease-in-out hover:cursor-pointer">
                            <span>View Entire Guest List:</span>
                            <div onClick={guestList} className="text-primary ">
                                <BsCardChecklist className="mx-1 text-info" size={'30px'} />
                            </div>
                        </div>
                        <div className="flex flex-row gap-4 items-center py-2">
                            <span>Download event summary: </span>
                            <div className="" onClick={handleExcelExport}><SiMicrosoftexcel className="text-accent hover:cursor-pointer hover:text-info" size={'30px'} /></div>
                            {/* <div className="btn btn-circle bg-accent" onClick={handleExcelExport}>Xcel File</div> */}
                        </div>
                    </div>
                    <div className="btn btn-block" onClick={handleSeparatelyPurchasedItemModal}>Add Item purchased separately</div>
                </div>
                <div className={`tableTainer ${(whatsClaimed?.length !== 0 || itemsSplit.length !== 0) ? 'hidden' : ''}`}>
                    <h2>You have not claimed any gifts for this event!</h2>
                </div>
            </div>
                <div className={`flex items-center justify-center flex-col my-2 ${(whatsClaimed?.length !== 0 || itemsSplit.length !== 0) ? '' : 'hidden'}`}>
                    <table className="table table-zebra sm:w-3/4 w-[95%]" id="eventInfoTable">
                        <tbody className="border border-secondary">
                            <tr className="border border-primary" align={"center"}>
                                <th>Claimed Items</th>
                                <th>For</th>
                                <th>Link</th>
                                <th>Cost</th>
                            </tr>

                            {/* {itemsClaimed?.map((i, index) => <tr key={index}><td>{i.giftName}</td><td>{i.username || i.requestor}</td><td>{i.giftLink}</td><td>{i.giftCost}$</td></tr>)} */}
                            {eventsData && mapIt(itemsClaimed)}

                            {/* <tr ><td colSpan={'4'}><span className="total border border-info p-4">Overall Spent: {Math.round(total - roundDifference)}$</span></td></tr> */}
                        </tbody>
                    </table>
                    <table className="table table-zebra sm:w-3/4 w-[95%]" id="eventInfoTable">
                        <tbody className="border border-secondary">
                            <tr className="border border-primary" align={"center"}>
                                <th>Separate items</th>
                                <th>For</th>
                                <th>Link</th>
                                <th>Cost</th>
                                <th>Delete</th>
                            </tr>
                            {mySeparateGifts && mapSeparateGifts(mySeparateGifts)}
                        </tbody>
                    </table>
                    <div className="border border-info p-4 my-4 flex items-center justify-center w-[95%] sm:w-3/4 mx-auto border-dashed">
                        Overall Spent: <span className="text-2xl mx-4">{Math.round((total - roundDifference) + separateGiftTotals)}</span>$
                    </div>
                    <div
                        className="text-info text-xl italic flex items-center justify-center btn  mx-auto mb-36"
                        onClick={handleModal}
                        disabled={disableThank}
                    >
                        {countdown}
                    </div>
                </div>

                <dialog id="my_modal_1" className="modal" ref={modalRef}>
                    <div className="modal-box md:w-5/6">
                        <WhomToThank data={whoBoughtMeStuff} />
                        <div className="modal-action">
                        <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <button className="btn">Close</button>
                        </form>
                        </div>
                    </div>
                </dialog>

                <dialog ref={separateItemRef} className="modal">
                    <div className="modal-box flex items-center justify-center flex-col">
                        <h3 className="font-bold text-lg text-center">Enter Item Data:</h3>
                        <label className="form-control w-full max-w-xs">
                            <div className="label">
                                <span className="label-text">Gift Name:</span>
                            </div>
                            <input
                                type="text"
                                name="giftName"
                                placeholder="Type here"
                                className="input input-bordered w-full max-w-xs"
                                onChange={handleSeparateItemsInput}
                            />
                        </label>
                        <label className="form-control w-full max-w-xs">
                            <div className="label">
                                <span className="label-text">Gift Link:</span>
                            </div>
                            <input
                                type="text"
                                name="giftLink"
                                placeholder="Type here"
                                className="input input-bordered w-full max-w-xs"
                                onChange={handleSeparateItemsInput}
                            />
                        </label>
                        <label className="form-control w-full max-w-xs">
                            <div className="label">
                                <span className="label-text">Gift Cost:</span>
                            </div>
                            <input
                                type="text"
                                name="giftCost"
                                placeholder="Type here"
                                className="input input-bordered w-full max-w-xs"
                                onChange={handleSeparateItemsInput}
                            />
                        </label>
                        <label className="form-control w-full max-w-xs">
                            <div className="label">
                                <span className="label-text">Gift Recipient:</span>
                            </div>
                            <input
                                type="text"
                                name="requestor"
                                placeholder="Type here"
                                className="input input-bordered w-full max-w-xs"
                                onChange={handleSeparateItemsInput}
                            />
                        </label>
                        <div className="modal-action">
                            <button className={`btn ${ loading ? 'disabled loading loading-spinner' : ''}`} onClick={submitSeparatelyPurchasedItem}>Save</button>
                        <form method="dialog">
                            <button className="btn">Close</button>
                        </form>
                        </div>
                    </div>
                </dialog>

        </>
    )
}