
import { Link } from 'react-router-dom';



export default function Card({ img, title, info, href, alt })
{





    return (
        <>
            <Link className='no-underline' to={href}>
                <div className="card w-72 lg:w-96 h-72 bg-base-100 shadow-xl image-full hover:scale-[105%] duration-200 mt-4">
                    <figure><img className='w-full' src={img} alt={alt} /></figure>
                    <div className="card-body">
                        <h2 className="card-title">{title}</h2>
                        <p>{info}</p>
                        <div className="card-actions justify-end">
                        <button className="btn btn-primary btn-circle text-xl font-bold italic animate-bounce">go</button>

                        {/* {title.startsWith('Create') ? (
                            <div className="dropdown">
                            <label tabIndex={0} className="btn btn-primary btn-circle text-2xl font-bold">+</label>
                            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                            <li><a>Item 1</a></li>
                            <li><a>Item 2</a></li>
                            </ul>
                        </div>
                        )
                        : <Link href={href}><button className="btn btn-primary btn-circle text-2xl font-bold">+</button></Link>} */}
                        </div>
                    </div>
                </div>
            </Link>
        </>
    );
}