import React, { useEffect, useRef, useState } from 'react';
import { getDoc, doc } from "firebase/firestore";
import { auth, db } from '../../firebase';
import EditProfile from '../profile/EditProfile';
import './dashboard.css';
import '../swalstyles.css';
import { sendEmailVerification } from 'firebase/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import ColorScheme from '../colorscheme/ColorScheme';
import DashOptions from '../dash/DashOptions';



export default () => {
    const colorRef1 = useRef(null);
    const colorRef2 = useRef(null);
    const [colors, setColors] = useState({});
    // document.querySelector('html').dataset.theme = 'primary'


    const user = auth.currentUser // production
    const navigate = useNavigate();

    const [userData, setUserData] = useState('');


    let userRef = doc(db, 'users', user.uid);
    useEffect(() => {
        setColors({
            primary: window.getComputedStyle(colorRef1.current).backgroundColor,
            secondary: window.getComputedStyle(colorRef2.current).backgroundColor
          });
        //   console.log(colors.primary);


        const getUserInfo = async () => {
            const docSnap = await getDoc(userRef);

            if (docSnap.exists()) {
                setUserData(docSnap.data())
                // console.log('user data', docSnap.data());

                document.querySelector('html').dataset.theme = docSnap.data().themePreference

            } else {
                return
            }
        }
        getUserInfo();

        // onAuthStateChanged(auth, user => {
        //     if (user) {
        //         user.emailVerified === false && Swal.fire({
        //             title:'Before you can add events and gifts you must verify your email!',
        //             confirmButtonColor: 'pink',
        //         })
        //         console.log(user);
        //     } else {
        //         console.log('no user');
        //     }
        // })

        // const updateE = async () => {
        //     try {
        //         await setDoc(userRef, {
        //             emailVerified: false
        //         })
        //     } catch (e) {
        //         console.log(e);
        //     }
        // }
        // updateE();

        // onAuthStateChanged(auth, user => {
        //     if (user) {

        //     } else {
        //         console.log('no user');
        //     }
        // })



    }, [])


    // let actionCodeSettings = {
    //     url: 'https://claims.gifts/?email=' + user.email,
    //     handleCodeInApp: false,
    // }

    // user.sendEmailVerification(actionCodeSettings)

    const verifyEmail = async () => {
            try {
                await sendEmailVerification(auth.currentUser)
                    .then(() => {
                        Swal.fire({
                            title: 'Your verification email has been sent!',
                            html: '<u>Please check your spam folder.</u>',
                            confirmButtonColor:''
                        })
                    })
            } catch(e) {
                // console.log(e)
                if (e.code === 'auth/too-many-requests') {
                    Swal.fire({
                        title: 'The server is busy, please try again in 10 seconds...',
                        confirmButtonColor: ''
                    })
                }
            }
    }

    return (
        <>
            <div className=''>
                {/* colors */}
            <div className='hidden'>
                <div className='w-2 h-2 bg-primary' ref={colorRef1}></div>
                <div className='w-2 h-2 bg-secondary' ref={colorRef2}></div>
            </div>
                <h2 className='mb-4 text-3xl md:text-4xl italic text-center' id="">Dashboard</h2>
                <div className='flex flex-row items-center justify-center'>
                    <div className='grid grid-rows-1 grid-cols-1 w-full'>
                        <div className='col-start-1 row-start-1 mr-auto sm:mr-0 px-4'>
                            <EditProfile colors={colors} currentUser={user} userData={userData} />
                        </div>
                        <div className='col-start-1 row-start-1 ml-auto'>

                            <ColorScheme />
                        </div>
                    </div>

                </div>
                <div>
                    {/* {!user.emailVerified === false && <button id="verifyemailBtn" className='btnInvert' onClick={verifyEmail}><GiCheckMark />Send Verification Email</button>} */}
                </div>
                {/* <AddEventForm colors={colors} /> */}
                <div className='my-8'>
                    <DashOptions />
                </div>


            </div>
        </>
    )
}