import { useRef, useState } from "react";
import { BsInfoCircle } from 'react-icons/bs';


const UpdateGiftInfo = ({ index, giftArray, updateClaimed, testing }) => {
    const giftInfo = giftArray[index].giftInfo;
    const giftPrice = giftArray[index].giftCost;
    const dialogRef = useRef();
    const textAreaRef = useRef();
    const [textVal, setTextVal] = useState(null);

    const handleText = e => {
        if (e) {
            setTextVal(e.target.value);
        }
    }
    const handleClose = () => {
        setTextVal("");
        textAreaRef.current.value = "";
    }
    const handleConfirm = async () => {
        try {
            if (textVal === "") {
                return;
            } else {
                giftArray[index].giftInfo = textVal;
                const success = await updateClaimed();
                if (success) {
                    dialogRef.current.close();
                    setTextVal("");
                    textAreaRef.current.value = "";
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <div className="text-info cursor-pointer" onClick={()=>dialogRef.current.showModal()}><BsInfoCircle className="w-6 h-6" /></div>
            <dialog ref={dialogRef} className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Gift Info:</h3>
                    <div>
                        <ul>
                            <li className={`text-md indent-5 italic ${giftInfo ? "" : "text-warning"}`}>Details:&nbsp;{giftInfo ? giftInfo : "No gift information has been entered..."}</li>
                            <li className="text-md indent-5 italic">Price:&nbsp;&nbsp;&nbsp;<span className="font-bold text-green-700">{giftPrice}&nbsp;$</span> </li>
                        </ul>
                    </div>
                    <p className="py-2 font-bold text-lg">Update Information:</p>
                    <textarea ref={textAreaRef} className="w-full border border-primary p-1" placeholder="Add/Edit item details e.g. size, color, quantity..." onChange={handleText}></textarea>
                    <div className="modal-action">
                        <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <div className="btn btn-primary mx-5" onClick={handleConfirm}>Confirm</div>
                            <button className="btn" onClick={handleClose}>Close</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </>
    );
}

export default UpdateGiftInfo;

// if (text) {

//     giftArray[index].giftInfo = text
//     // console.log(text);

//     Swal.fire({
//         title: 'Gift Information: ',
//         text: `${text}`,
//         confirmButtonColor: ''
//     })
//     .then(() => updateClaimed())