import { useEffect, useRef, useState } from "react";
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import UpdateUserName from "./UpdateUserName";
import UploadImage from "./UploadImage";
import './profilepage.css';
import Swal from "sweetalert2";
import ChangePassword from "../reset/ChangePassword";


export default () => {
    const params = useParams();
    const userId = params.profile;
    const userRef = doc(db, 'users', userId);
    const [userInfo, setUserInfo] = useState({})
    const modalRef = useRef()

    useEffect(() => {

        const getUserInfo = async () => {
            const docSnap = await getDoc(userRef)
            try {
                if (docSnap.exists()) {
                    setUserInfo({ username: docSnap.data().username, img: docSnap.data().img, userEmail: docSnap.data().email })
                }
            } catch(error) {
                if (error) {
                    Swal.fire({
                        title: 'Error',
                        text: 'Please try again!',
                        confirmButtonColor: ''
                    })
                }

            }
        }
        getUserInfo();
    }, []);

    const handleModal = () => {
        modalRef.current.showModal()
    }
    console.log('userInfo\t', userInfo)



    return (
        <>
            <div className="flex items-center justify-center flex-col pb-24">
                <div className="text-center">
                <span>Username:</span>
                <h3 className="font-bold text-2xl">{userInfo.username ? userInfo.username : userInfo.userEmail}</h3>
                {userInfo.username ? <></> : <span><i>(defaults to email)</i></span>}
                    <UploadImage userId={userId} userInfo={userInfo} />
                </div>
                <hr />
                <div className="my-2">
                    <UpdateUserName userId={userId} userInfo={userInfo} />
                </div>
                <hr />
                <div className="mx-auto">
                    <button className="btn btn-large btn-warning" onClick={handleModal}>Reset Password</button>
                </div>
            </div>

            <dialog id="my_modal_1" className="modal" ref={modalRef}>
                <div className="modal-box">
                    <ChangePassword modalRef={modalRef} />
                    <div className="modal-action">
                        <form method="dialog">
                            <button className="btn">Close</button>
                        </form>
                    </div>
                </div>
            </dialog>

        </>
    );
}