

export default function EmptyGiftAndSearch({ giftArray, inFocus }) {
    return (
        <>
            {giftArray.length === 0 && !inFocus ?
                (
                    <div className='my-2 py-4'>
                        <div className='border border-primary font-italic p-4 m-4'>
                            <h2>Add a gift and its corresponding link using the <u>inputs above</u> to get started...</h2>
                        </div>
                    </div>
                ) : <></>
            }{
                giftArray.length === 0 && inFocus ?
                (
                    <div>
                        <div>
                            <h2 className='font-italic p-4 m-4 border border-error rounded-md border-dashed text-warning'>Nothing matches your search...</h2>
                        </div>
                    </div>
                ) : <></>
            }
        </>
    );
}

// ORIGINAL IN GIFTS.JS
{/* {giftArray.length === 0 && !inFocus &&
    (
        <tr className='my-2 py-4'>
            <td className='border border-primary'>
                <h2>Add a gift and its corresponding link using the inputs above to get started...</h2>
            </td>
        </tr>
    )
}
{giftArray.length === 0 && inFocus &&
    (
        <tr>
            <td>
                <h2 className='font-italic p-4 m-4 border border-error rounded-md border-dashed'>Nothing matches your search...</h2>
            </td>
        </tr>
    )
} */}