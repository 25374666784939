import { useEffect, useState } from "react";

export default function DisplayConfirmation({ text, displayConfState, setDisplayConfState }) {
    const [toastClass, setToastClass] = useState("hidden opacity-0 transition-opacity duration-1000 ease-in-out");

    const resetState = () => {
        setDisplayConfState(() => ({ success: false, displayError: false }));
    };

    useEffect(() => {
        if (displayConfState.success || displayConfState.displayError) {
            displayConf();
        }
    }, [displayConfState]);

    function displayConf() {
        setToastClass('opacity-100 transition-opacity duration-1000 ease-in-out');
        setTimeout(() => {
            setToastClass('opacity-0 transition-opacity duration-1000 ease-in-out');
            setTimeout(() => {
                setToastClass('hidden opacity-0 transition-opacity duration-1000 ease-in-out');
                resetState();
            }, 500);
        }, 1500);
    }

    return (
        <>
            <div className={toastClass}>
                {displayConfState.displayError
                ?
                    <div className="toast toast-center toast-middle z-50">
                        <div className="alert alert-error">
                            <span>Error, try again!</span>
                        </div>
                    </div>
                :
                    <div className="toast toast-center toast-middle z-50">
                        <div className="alert alert-success">
                            <span>{text} added!</span>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}