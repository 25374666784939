
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoMdLogOut } from 'react-icons/io';
import { HiOutlineLogin } from 'react-icons/hi';
import logo from '../../images/cg.png'
import './navbar.css'

 // me-auto nav classname
export default () => {
    const location = useLocation();
    let navigate = useNavigate();
    const lP = location.pathname
    // console.log(params, lP);


    return (
        <>
           <nav className='navbar bg-base-100 flex flex-row items-center justify-center'>

                <span className='navbarlinks flex-1  '>             <Link className='text-primary text-4xl italic no-underline font-bold'  to={lP === '/departure' ? "/" : "#"}><img className='w-1/2' src={logo} /></Link></span>
                {lP === '/dashboard' && <span><Link className='navbarlinks mr-4 text-info' to={'/dashboard/about'}>Info</Link></span>}
                {/* {lP !== '/dashboard' && user       && lP !== '/departure' && lP !== '/' && lP !== '/oauth'  && lP !== '/signup' && <Link to="/dashboard" className='navbarlinks'>Dashboard</Link>} */}
                {lP !== '/signup'    && lP !== '/' && lP !== '/departure' && lP !== '/' && lP !== '/oauth'  &&                     <Link to="/logout" className='text-info text-lg italic mr-4 hover:text-error hover:scale-[115%] hover:duration-200 ease-in-out'>Logout <IoMdLogOut className='ml-2' size={'20px'} /></Link>}
                {lP === '/departure' &&                    <Link to={'/'}><h3 className='text-info mr-2 hover:text-primary'>Login    <HiOutlineLogin color='white' cursor={'pointer'} /></h3></Link>}
                {lP === '/' && <div className='italic text-secondary mr-2 font-bold skew-y-3 drop-shadow-lg animate-pulse'>Event Organization App.</div>}
                {lP === '/oauth' && <p className='italic text-secondary mr-2 font-bold drop-shadow-lg'>&quot;Easy log in with your email&quot;</p>}
            </nav>
        </>
    )
}