import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { AuthContext } from "../context/AuthContext";
import Swal from "sweetalert2";
import { AiOutlineGoogle } from 'react-icons/ai';
import { AiOutlineYahoo } from 'react-icons/ai';
// import oauth2 from '../images/Oauth_logo2.svg.png';
// import './login.css'




export default () => {
    const [error, setError] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState('hidden');

    const navigate = useNavigate();

    const { dispatch } = useContext(AuthContext);

    useEffect(() => {



    }, [])




    const handleSubmit = e => {
        e.preventDefault();
        if (email === '' || password === '') return Swal.fire({ title: 'You must enter both an email and password!', confirmButtonColor: ''}).then(() => setError(false))

        setLoading('block loading loading-spinner')


        signInWithEmailAndPassword(auth, email.trim(), password.trim())
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                dispatch({ type: "LOGIN", payload: user })


                navigate('/dashboard')
            })
            .catch((error) => {
                setLoading('hidden')
                setError(true)
                console.log(error.code);
                switch
                    (error.code) {
                        case "auth/user-not-found":
                            setErrorText('No account registered under this email!')
                            break;
                        case "wrong-password":
                            setErrorText('Wrong email or password!')
                            break;
                        case "account-exists-with-different-credential":
                            setErrorText('An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.')
                            break;
                        default:
                            setErrorText('An unexpected error occured, please try again!')
                    }

            });
    }

    return (
        <>
            <div className="flex items-center justify-center flex-col w-full mb-16">
                    <label className="text-4xl text-primary text-center">Welcome to <span className="italic text-secondary">Claims Gifts!</span></label>
                <div className="mx-auto">
                    <p>Please log in to continue...</p>
                <form onSubmit={handleSubmit}>
                    <div className="my-2">
                        <input
                            placeholder="email"
                            onChange={e => setEmail(e.target.value)}
                            onFocus={e => e.target.placeholder = ''}
                            onBlur={e => e.target.placeholder = 'email'}
                            className="input input-lg border border-primary w-[300px] sm:w-[400px]"
                        />
                    </div>
                    <div className="my-2">
                        <input
                            type="password"
                            placeholder="password"
                            onChange={e => setPassword(e.target.value)}
                            onFocus={e => e.target.placeholder = ''}
                            onBlur={e => e.target.placeholder = 'password'}
                            className="input input-lg border border-primary w-[300px] sm:w-[400px]"
                        />
                    </div>
                <div className="inputDivs">
                    <button type="submit" className="btn btn-primary hover:bg-secondary my-2 hover:scale-125 ease-in-out duration-200">Log in<span className={`${loading}`}></span></button>
                </div>
                    {error && <span >{errorText}</span>}
                </form>
                <div className="newuser">
                    <p className="mb-2 italic">New User?</p>
                    <Link id="signup" to="/signup">
                        <div className="btn btn-accent hover:bg-success hover:scale-125 ease-in-out duration-200">
                            Sign Up
                        </div>
                    </Link>
                    <p className="text-info italic my-2 hover:underline"><Link className="hover:underline" to="/resetpassword">Forgot password?</Link></p>

                    {/* <p className="my-2 italic">Or...</p> */}
                    {/* <p className="my-2 indent-1">use passwordless <span className="text-success">(OAuth)</span> sign-in:</p> */}
                    <div className="flex flex-col my-12">

                    <p className="my-2 italic">&nbsp;</p>
                    <p className="my-2 indent-1">(OAuth)<span className="text-success italic">(temporarily)</span> disabled...</p>
                    {/* <Link id="signup" to="/oauth"> */}
                        <div className="btn btn-accent-content hover:bg-secondary text-base" disabled>Sign In via <AiOutlineGoogle width={'40px'} /> <AiOutlineYahoo width={'40px'} />
                        </div>
                    </div>
                    {/* </Link> */}
                    {/* <img src={oauth2} width={'40px'} /> */}
                </div>
            </div>
            </div>

        </>
    )
}