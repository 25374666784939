import React, { useState, useEffect, useRef } from 'react';
import { doc, addDoc, collection, updateDoc, arrayUnion, query, where, getDocs, getDoc, onSnapshot, deleteDoc } from "firebase/firestore";
import { TbMailbox } from 'react-icons/tb';
import Swal from 'sweetalert2';

export const CheckInvitesDialog = ({ inviteData, setAppendedInvite, setDocData, eventParticipants, setEventParticipants, db }) => {

    const invitationDialogRef = useRef();
    const [mapInvites, setMapInvites] = useState([]);
    const message = {
        info: "No new invitations available!",
        success: "You have been successfully added to the event list with name:",
    };
    const [inviteVal, setInviteVal] = useState("");
    const handleSelect = (e) => {
        let val = e.target.value;
        setInviteVal(val);
    }

    useEffect(() => {
        // console.log('inviteData\t', inviteData);
        if (inviteData.length) {
            setMapInvites(inviteData.filter(i => !i.acceptedInvite === true));
        }
    }, [inviteData])

    const handleCheckEventClick = () => {
        invitationDialogRef.current.showModal();
        // const confirmInvite = async () => {
        //     const { value: choice } = await Swal.fire({
        //         title: `Invitations:`,
        //         input: 'select',
        //         inputOptions: {
        //             'Invitations': {
        //                 ...mapInvites
        //             }
        //         },
        //         confirmButtonColor: '',
        //         showCancelButton: true,
        //     })
        //     if (choice) {

        //         const eventConfirmed = filtAcceptedInvites[choice]
        //         const eventConfirmedId = filtAcceptedInvites[choice].eventId
        //         const inviteDocId = filtAcceptedInvites[choice].id

        //         const fixedDate = eventConfirmed.eventDate.slice(5) + '-' + eventConfirmed.eventDate.slice(0, 4)

        //         Swal.fire({
        //             title: `You have been been added to "The List" \nFor event: \n"${eventConfirmed.event}" \n${fixedDate}`,
        //             confirmButtonColor: ''
        //         })
        //         // .then(() => setAgreedEvent(true))
        //         .then(() => updateDoc(doc(db, 'invites', inviteDocId),
        //         { acceptedInvite: true }
        //         ))
        //         .catch((err) => console.log(err))

        //         const addMeToEvent = async () => {
        //             let list = []
        //             const docRef = doc(db, "events", eventConfirmedId)
        //             const docSnap = await getDoc(docRef)
        //             if (docSnap.exists()) {
        //                 // console.log('docSnap .data()', docSnap.data())
        //                 list.push(docSnap.data().eventParticipants)
        //                 setEventParticipants(prev => prev, ...list)
        //             } else {
        //                 console.log('No such doc!');
        //             }
        //             console.log(list)
        //         }
        //         addMeToEvent()

        //         const updateEventPartici = async () => {
        //             const updatedParticiRef = doc(db, "events", eventConfirmedId)
        //             await updateDoc(updatedParticiRef, {
        //                 eventParticipants: arrayUnion(...eventParticipants)
        //             })
        //         }
        //         updateEventPartici()

        //         const getSingleDoc = async () => {
        //             let arr = [];
        //             const singleDocRef = doc(db, "events", eventConfirmedId)
        //             const docSnap = await getDoc(singleDocRef)
        //             if (docSnap.exists()) {
        //                 // console.log(docSnap.data())
        //                 arr.push({id: docSnap.id, ...docSnap.data()})
        //                 setAppendedInvite(docSnap.data())
        //                 setDocData(prev => [...prev, {id: docSnap.id, ...docSnap.data()}])
        //             }
        //         }
        //         getSingleDoc()
        //     }
        // }
        // confirmInvite()
    }

    const handleInvitationSubmit = async () => {
        const [inviteDocId, eventConfirmedId, eventConfirmed] = inviteVal.split(",").map(i => i.trim());
        console.log('id, eventId, eventName\t', inviteDocId, eventConfirmedId, eventConfirmed);
        console.log('eventId\t', eventConfirmedId);

        try {
            await updateDoc(doc(db, 'invites', inviteDocId), { acceptedInvite: true });
            const addMeToEvent = async () => {
                try {
                    let list = []
                    const docRef = doc(db, "events", eventConfirmedId);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        // console.log('docSnap .data()', docSnap.data())
                        list.push(docSnap.data().eventParticipants)
                        setEventParticipants(prev => prev, ...list)
                    } else {
                        console.log('No such doc!');
                    }
                    console.log(list)
                } catch (error) {
                    console.error(error);
                }
            }
            addMeToEvent();

            const updateEventPartici = async () => {
                try {
                    const updatedParticiRef = doc(db, "events", eventConfirmedId);
                    await updateDoc(updatedParticiRef, {
                        eventParticipants: arrayUnion(...eventParticipants)
                    });
                } catch (error) {
                    console.error(error);
                }
            }
            updateEventPartici();

            const getSingleDoc = async () => {
                try {
                    let arr = [];
                    const singleDocRef = doc(db, "events", eventConfirmedId)
                    const docSnap = await getDoc(singleDocRef)
                    if (docSnap.exists()) {
                        // console.log(docSnap.data())
                        arr.push({id: docSnap.id, ...docSnap.data()})
                        setAppendedInvite(docSnap.data())
                        setDocData(prev => [...prev, {id: docSnap.id, ...docSnap.data()}])
                    }
                } catch (error) {
                    console.log("getSingleDoc Failed...")
                    console.error(error);
                }
            }
            getSingleDoc();

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <div className='chkInvHide my-2 w-fit'>Check Event Invite
                <div className='hover:cursor-pointer'
                    onClick={handleCheckEventClick}>
                        <TbMailbox className='text-primary hover:text-secondary hover:scale-[115%] hover:duration-200 ease-in-out' size={'35px'} />
                </div>
            </div>
            {/* <button className="btn" onClick={()=>document.getElementById('invitationDialog').showModal()}>open modal</button> */}
                <dialog ref={invitationDialogRef} className="modal">
                    {mapInvites?.length ?
                        <div className="modal-box">
                            <h3 className="font-bold text-lg">Invitations:</h3>
                            <select className="select select-bordered w-full max-w-xs" onClick={handleSelect}>
                                {mapInvites?.map((invite) => {
                                    return (
                                        <option key={invite.eventId} value={`${invite.id}, ${invite.eventId}, ${invite.event}`}>
                                            Event: {invite?.event} | Invited By:{invite?.invitedBy}
                                        </option>
                                    );
                                })}
                            </select>
                            <div className="modal-action">
                                <form method="dialog">
                                    <button className="btn" onClick={handleInvitationSubmit}>Accept</button>
                                    <button className="btn">Close</button>
                                </form>
                            </div>
                        </div>
                    : <div className="modal-box">
                        <h3 className="font-bold text-lg">{message.info}</h3>
                        <form method='dialog'>
                            <button className='btn'>Close</button>
                        </form>
                      </div>
                    }
                </dialog>
        </>
    );
}

