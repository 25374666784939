import { Link } from "react-router-dom";
import './editprofile.css';
import noProfilePic from './blank-profile.webp'

export default ({currentUser, userData}) => {
    // const user = currentUser.currentUser

    return (
        <>
            <div className="flex flex-col items-center justify-center">
                <Link to={`/dashboard/user/${currentUser.uid}`}>
                    <img className="rounded-full object-cover w-24 h-24 sm:w-36 sm:h-36"  src={userData.img ? userData.img : noProfilePic} />
                </Link>
                <h3>{currentUser && userData.username ? userData.username : currentUser.email}</h3>
            </div>
        </>
    )
}