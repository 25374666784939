const GiftStatusToggle = ({ toggle, handleToggle, giftArray }) => {
    return (
        <>
        <div className={giftArray.length === 0 ? 'none' : 'thclaimed2 my-8 p-4 flex flex-col items-center justify-center'}>
            <span className="text-center">Toggle to {!toggle ? "reveal" : "hide"} the 'claimed' status of your items:</span>
            <div className="mt-2 pt-2">
                <label className="relative inline-block w-16 h-8">
                    <input
                        type="checkbox"
                        className="opacity-0 w-0 h-0 peer"
                        value={toggle}
                        defaultChecked={false}
                        onClick={handleToggle}
                        />
                    <span
                        className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-info transition duration-400 rounded-full peer-checked:bg-secondary peer-focus:shadow-white">
                    </span>
                    <span
                        className="absolute left-1 bottom-1 w-6 h-6 bg-black transition duration-400 rounded-full peer-checked:translate-x-8">
                    </span>
                </label>
            </div>
            {!toggle ? "(hidden)" : "(revealed)"}
            </div>
        </>
    );
}

export default GiftStatusToggle;